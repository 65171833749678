<template>
  <div>
    <v-form style="width:300px;">
      <v-text-field v-model="username" label="用户名" required></v-text-field>
      <v-text-field v-model="email" label="邮箱" required></v-text-field>
      <v-text-field v-model="password" label="密码" required type="password">
      </v-text-field>
      <!-- <v-row>
        <v-col>
          <v-text-field v-model="captcha" label="验证码" hide-details="auto"></v-text-field>
        </v-col>
        <v-col>
          <img @click="getCaptcha" style="height:50px;" :src="captchaDetail.data" alt="">
        </v-col>
      </v-row> -->
      <v-btn block depressed color="primary" style="margin-top:20px;color:white;" @click="onRegister"
        :loading="isLoading" :disabled="isDisabled">
        注册
      </v-btn>

      <div style="display:flex;justify-content:space-between;align-items:center;">
        <v-btn text color="primary" style="margin-top:8px;" @click="redirectLogin">立即登录！
        </v-btn>
        <!-- <v-btn text color="primary" style="margin-top:8px;" @click="redirectForget">忘记密码！
        </v-btn> -->
      </div>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ errText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  export default {
    data: () => ({
      username: '',
      email: '',
      password: '',
      captcha: '',
      captchaDetail: '',
      snackbar: false,
      errText: '',
      timeout: 2000,

      isLoading: false,
      isDisabled: false,
    }),
    methods: {
      redirectForget() {
        this.$router.push("forget");
      },
      redirectLogin() {
        this.$router.push("login");
      },
      getCaptcha() {
        this.$service.auth.getCaptchaService()
          .then(res => {
            if (res['code'] == 0) {
              this.captchaDetail = res['data'];
            }
          })
      },
      onRegister() {
        this.isLoading = true;
        this.isDisabled = true;
        const jsonData = {
          "username": this.username,
          "password": this.password,
          "mail": this.email,
        }
        this.$service.auth.registerService(jsonData)
          .then(res => {
            if (res['code'] == 0) {
              this.$router.push('login');
            } else {
              this.errText = res['message'];
              this.snackbar = true;
            }
            this.isLoading = false;
            this.isDisabled = false;
          })
      }
    },
  }
</script>